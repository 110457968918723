<template>
  <div class="app-container">

    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入商品订单后4位/手机号/取餐码" style="width: 280px;" class="filter-item" clearable/>
      <el-select class="filter-item" v-model="listQuery.device_id" placeholder="请选择设备" style="width: 140px" clearable>
        <el-option v-for="item in devices" :key="item.device_id" :label="item.device_address_and_name" :value="item.device_id"></el-option>
      </el-select>
      <el-date-picker v-model="listQuery.start_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="开始日期" clearable></el-date-picker>
      <el-date-picker v-model="listQuery.end_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" placeholder="截止日期" clearable></el-date-picker>
      <el-select class="filter-item" v-model="listQuery.state" placeholder="请选择状态" style="width: 140px" clearable>
        <el-option label="待投餐" :value="0"></el-option>
        <el-option label="待取餐" :value="1"></el-option>
        <el-option label="已取餐" :value="2"></el-option>
        <el-option label="已滞留" :value="4"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column type="expand" min-width="40">
        <template slot-scope="props">
          <div style="margin-left: 10%">
            <el-form label-position="left" inline class="custom-inline-form">
              <el-row>箱子信息</el-row>
              <el-form-item label="设备名称:">
                <span>{{ props.row.device_name }}</span>
              </el-form-item>
              <el-form-item label="箱子名称:">
                <span>{{ props.row.box_name }}</span>
              </el-form-item>
              <el-form-item label="箱子大小:">
                <span>{{ props.row.box_size == 'S' ? '小箱' : '大箱' }}</span>
              </el-form-item>
            </el-form>
            <el-form label-position="left" inline class="custom-inline-form">
              <el-row>订单信息</el-row>
              <el-form-item label="投餐方式:">
                <span v-if="props.row.put_meal_type == 1">订单号投餐</span>
                <span v-else-if="props.row.put_meal_type == 2">专属号投餐</span>
              </el-form-item>
              <el-form-item label="专属号:">
                <span>{{ props.row.u_no }}</span>
              </el-form-item>
              <el-form-item label="商铺订单号:">
                <span>{{ props.row.order_no }}</span>
              </el-form-item>
              <br/>
              <el-form-item label="投餐时间:">
                <span>{{ props.row.put_meal_time }}</span>
              </el-form-item>
              <el-form-item label="取餐码:">
                <span>{{ props.row.take_code }}</span>
              </el-form-item>
              <el-form-item label="取餐时间:">
                <span>{{ props.row.take_meal_time }}</span>
              </el-form-item>
            </el-form>
            <el-form label-position="left" inline class="custom-inline-form">
              <el-row>投餐员信息</el-row>
              <el-form-item label="姓名:">
                <span>{{ props.row.sendman.name }}</span>
              </el-form-item>
              <el-form-item label="手机号:">
                <span>{{ props.row.sendman.phone }}</span>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="投餐单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.device_order_no }}
        </template>
      </el-table-column>
      <el-table-column label="投餐方式" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.put_meal_type == 1">快捷投餐</span>
          <span v-else-if="scope.row.put_meal_type == 2">手机号投餐</span>
          <span v-else-if="scope.row.put_meal_type == 3">扫码投餐</span>
        </template>
      </el-table-column>
      <el-table-column label="设备" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.device_name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="箱子" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.box_name }}({{ scope.row.box_size == 'S' ? '小箱' : '大箱' }})</div>
        </template>
      </el-table-column>
      <el-table-column label="是否加热" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.is_heat == 1 ? '加热' : '常温' }}
        </template>
      </el-table-column>
      <el-table-column label="取餐码" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.take_code }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.state == 0">待投餐</el-tag>
          <el-tag type="info" v-if="scope.row.state == 1">待取餐</el-tag>
          <el-tag type="success" v-if="scope.row.state == 2">已取餐</el-tag>
          <el-tag type="warning" v-if="scope.row.state == 3">已取消</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button v-if="scope.row.state == 1" type="primary" size="mini" @click="openBox(scope.row)">开箱</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import { getQiniuToken } from "@/api/common";
import { guid } from "@/utils/index";

export default {
  data() {
    return {
      devices: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
        start_time: "",
        end_time: "",
        state: '',
        device_id: '',
      },
      dialogVisible: false,
      btnLoading: false
    };
  },
  created() {
    this.getList();
    this.getDeviceList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getDeviceList();
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/deviceOrder/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getDeviceList() {
      request({
        url: "/api/backend/device/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
        }
      }).then(response => {
        this.devices = response.data.data;
      });
    },
    uploadSuccess(res) {
      this.form.qr_url = this.qiniu.baseUrl + "/" + res.key;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    openBox(item) {
      this.$confirm("确定要打开箱子吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/box/open",
          method: "post",
          data: {
            ids: [],
            device_id: item.device_id,
            lock_id: item.lock_id,
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.custom-inline-form {
  margin-bottom: 10px;
  .el-row {
    padding: 4px 0;
    font-weight: bold;
  }
  .el-form-item {
    margin-bottom: 4px !important;
  }
  .el-form-item__label {
    line-height: 20px !important;
  }
  .el-form-item__content {
    line-height: 20px !important;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
